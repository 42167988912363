<template>
  <div>
    <div class="content" id="video-content">
      <video-player
        class="video-player vjs-custom-skin"
        ref="videoPlayer"
        :playsinline="true"
        :options="playerOptions"
        @play="onPlayerPlay($event)"
        @pause="onPlayerPause($event)"
        :events="events"
        @fullscreenchange="handlefullscreenchange"
      >
      </video-player>
    </div>
  </div>
</template>

<script>
// Similarly, you can also introduce the plugin resource pack you want to use within the component
// import 'some-videojs-plugin'
import { VueVideoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";

import { string } from "mathjs";

export default {
  data() {
    return {
      videoUrl:
        "https://yujianweilai02-tp5.oss-cn-beijing.aliyuncs.com/video/12%E6%9C%88%E5%BA%94%E6%80%A5%E7%AE%B1%E8%A7%86%E9%A2%91/12.23-%E7%AE%B1%E5%AD%90%E5%8F%91%E8%B4%A7.mp4",
      //配置
      playerOptions: {
        id: "vue-video-player",
        playbackRates: [0.5, 1.0, 1.5, 2.0], //可选择的播放速度
        autoplay: false, //如果true,浏览器准备好时开始放。
        muted: true, // 默认情况下将会消除任何音频。
        loop: false, // 视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "1:1", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4",
            src: "", //url地址
          },
        ],
        poster: "", //你的封面地址

        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, //当前时间和持续时间的分隔符
          durationDisplay: true, //显示持续时间
          remainingTimeDisplay: false, //是否显示剩余时间功能
          fullscreenToggle: true, //全屏按钮
        },
      },
    };
  },
  props: {
    videoUrl: {
      type: string,
      default: "",
    },
  },
  components: {
    VueVideoPlayer,
  },
  mounted() {
    console.log("this is current player instance object", this.player);
    this.playerOptions.sources[0].src = this.videoUrl;
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  methods: {
    // listen event
    onPlayerPlay(player) {
      // console.log('player play!', player)
    },
    onPlayerPause(player) {
      // console.log('player pause!', player)
    },
    // ...player event

    // or listen state event
    playerStateChanged(playerCurrentState) {
      // console.log('player current update state', playerCurrentState)
    },

    // player is ready
    playerReadied(player) {
      console.log("the player is readied", player);
      // you can use it to do something...
      // player.[methods]
    },
  },
};
</script>
<style scoped>
.vjs-custom-skin > .video-js .vjs-big-play-button {
  outline: none;
  border: none;
  width: 66px;
  height: 66px !important;
  background-color: rgba(0, 0, 0, 0) !important;
}
</style>