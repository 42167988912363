<template>
  <div>
    <el-dialog
      class="AddDialog"
      :title="tittle"
      :visible.sync="dialogVisible"
      width="700px"
      hegiht="1000px"
      :close-on-click-modal="false"
      @close="close"
    >
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        :rules="rules"
        label-width="auto"
        class="d"
      >
        <el-row :gutter="15">
          <el-form-item label="视频地址" prop="url">
            <el-upload
              v-loading="loading"
              element-loading-text="视频上传中"
              element-loading-spinner="el-icon-loading"
              class="avatar-uploader"
              :action="this.$server.api + 'command/ossUpload?filename=file'"
              :show-file-list="false"
              :before-upload="beforeUploadVideo"
              :on-progress="uploadProcess"
              style="width: 178px"
            >
              <videoPlayer
                style="width: 178px"
                v-if="videoUrl"
                :videoUrl="videoUrl"
                class="avatar"
              ></videoPlayer>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>

          <el-form-item label="视频封面图" prop="img">
            <el-upload
              class="avatar-uploader"
              :action="this.$server.api + 'command/ossUpload?filename=file'"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img v-if="imageUrl" :src="imageUrl" class="avatar" /><i
                v-else
                class="el-icon-plus avatar-uploader-icon"
              ></i>
            </el-upload>
          </el-form-item>

          <el-form-item label="视频标题" prop="title">
            <el-input
              v-model="ruleForm.title"
              style="width: 180px"
              placeholder="请输入视频标题"
            ></el-input>
          </el-form-item>

          <el-form-item label="视频描述" prop="content">
            <el-input
              v-model="ruleForm.content"
              style="width: 180px"
              placeholder="请输入视频描述"
            ></el-input>
          </el-form-item>

          <el-form-item label="视频状态显示(1显示 2隐藏)" prop="status">
            <el-input
              v-model="ruleForm.status"
              style="width: 180px"
              placeholder="请输入视频状态显示"
            ></el-input>
          </el-form-item>

          <el-form-item label="视频点击数量" prop="pv">
            <el-input
              v-model="ruleForm.pv"
              style="width: 180px"
              placeholder="请输入视频点击数量"
            ></el-input>
          </el-form-item>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="emptyForm">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { videoadd, videoedit } from "@/request/api";
import videoPlayer from "@/components/videoPlayer";
import { VueVideoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";

export default {
  name: "AddDialog",
  components: { VueVideoPlayer },
  data() {
    return {
      id: "",
      tittle: "",
      dialogVisible: false,
      loading: false,
      imageUrl: "",
      videoUrl: "",
      ruleForm: {
        url: "",
        img: "",
        title: "",
        content: "",
        status: "",
        pv: "",
      },
      content: "",
      rules: {
        url: [{ required: true, message: "请输入视频地址", trigger: "blur" }],
        img: [{ required: true, message: "请选择图片", trigger: "blur" }],
        title: [{ required: true, message: "请输入视频标题", trigger: "blur" }],
        content: [
          { required: true, message: "请输入视频描述", trigger: "blur" },
        ],
        status: [
          { required: true, message: "请选择视频状态", trigger: "blur" },
        ],

        pv: [
          { required: true, message: "请输入视频点击数量", trigger: "blur" },
        ],
      },
    };
  },
  components: {
    videoPlayer,
  },
  created() {},
  mounted() {},
  methods: {
    show(type, row) {
      this.dialogVisible = true;
      this.type = type;
      if (this.type == 2) {
        this.tittle = "编辑";

        this.id = row.id;
        this.imageUrl = row.img;
        this.videoUrl = row.url;
        this.ruleForm.img = row.img;
        this.ruleForm.url = row.url;
        this.ruleForm.title = row.title;
        this.ruleForm.content = row.content;
        this.ruleForm.status = row.status;
        this.ruleForm.pv = row.pv;
      } else {
        this.tittle = "添加";
      }
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    handleAvatarSuccess(res, file) {
      let imgurl = res.data;
      this.imageUrl = imgurl;
      this.ruleForm.img = imgurl;
      const data = file.raw;
      this.putObject(data);
    },
    async putObject(data) {
      let OSS = require("ali-oss");
      const client = new OSS({
        // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
        region: "oss-cn-beijing",
        // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
        accessKeyId: "LTAI5tS4HMwn4NcLE9Hf2iUa",
        accessKeySecret: "wGxicSjxwjRbJzza84PplXTuySdA1l",
        // 从STS服务获取的安全令牌（SecurityToken）。
        // stsToken: "yourSecurityToken",
        // 填写Bucket名称。
        bucket: "yujianweilai02-tp5",
      });
      try {
        // 填写Object完整路径。Object完整路径中不能包含Bucket名称。
        // 您可以通过自定义文件名（例如exampleobject.txt）或文件完整路径（例如exampledir/exampleobject.txt）的形式实现将数据上传到当前Bucket或Bucket中的指定目录。
        // data对象可以自定义为file对象、Blob数据或者OSS Buffer。
        const result = await client.put(
          "/image/" + new Date().getTime() + ".jpg",
          data
          // { headers }
        );
        this.ruleForm.img = result.url;
        console.log(result)
      } catch (e) {
        console.log(e);
      }
    },

    close() {
      this.dialogVisible = false;
      this.ruleForm.img = "";
      this.ruleForm.url = "";
      this.ruleForm.title = "";
      this.ruleForm.content = "";
      this.ruleForm.status = "";
      this.ruleForm.pv = "";
      this.imageUrl = "";
    },

    submitForm() {
      if (this.type == 1) {
        this.$refs.ruleForm.validate(async (valid) => {
          if (valid) {
            let token = sessionStorage.getItem("token");
            this.token = token;
            let params = {
              token: sessionStorage.getItem("token"),
              img: this.ruleForm.img,
              url: this.ruleForm.url,
              title: this.ruleForm.title,
              content: this.ruleForm.content,
              status: this.ruleForm.status,
              pv: this.ruleForm.pv,
            };
            videoadd(params).then((res) => {
              if (res.data.status == "200") {
                this.$message.success("新增成功");
                this.$parent.getVideoList();
                this.close();
                this.isDisable = false;
              } else {
                this.$message.error(res.data.msg);
                this.$parent.getVideoList();
                this.close();
                this.isDisable = false;
              }
            });
          } else {
            return false;
          }
        });
      } else {
        this.$refs.ruleForm.validate(async (valid) => {
          if (valid) {
            let token = sessionStorage.getItem("token");
            this.token = token;
            let params = {
              img: this.ruleForm.img,
              url: this.ruleForm.url,
              title: this.ruleForm.title,
              token: sessionStorage.getItem("token"),
              content: this.ruleForm.content,
              status: this.ruleForm.status,
              pv: this.ruleForm.pv,
              id: this.id,
            };
            videoedit(params).then((res) => {
              if (res.data.status == "200") {
                this.$message.success("修改成功");
                this.$parent.getVideoList();
                this.close();
                this.isDisable = false;
              } else {
                this.$message.error(res.data.msg);
                this.$parent.getVideoList();
                this.close();
                this.isDisable = false;
              }
            });
          } else {
            return false;
          }
        });
      }
    },
    emptyForm() {
      this.dialogVisible = false;
      this.ruleForm.img = "";
    },
    beforeUploadVideo(file) {
      var fileSize = file.size / 1024 / 1024 < 50;
      if (["video/mp4"].indexOf(file.type) == -1) {
        layer.msg("请上传正确的视频格式");
        return false;
      }
      if (!fileSize) {
        layer.msg("视频大小不能超过50MB");
        return false;
      }
      this.loading = true;
      this.putVideo(file);
      return fileSize;
    },

    async putVideo(res) {
      console.log(res, "111");
      let file = res;
      let OSS = require("ali-oss");
      const client = new OSS({
        // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
        region: "oss-cn-beijing",
        // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
        accessKeyId: "LTAI5tS4HMwn4NcLE9Hf2iUa",
        accessKeySecret: "wGxicSjxwjRbJzza84PplXTuySdA1l",
        // 从STS服务获取的安全令牌（SecurityToken）。
        // stsToken: "yourSecurityToken",
        // 填写Bucket名称。
        bucket: "yujianweilai02-tp5",
      });

      try {
        const result = await client.put(
          "/video/" + new Date().getTime() + ".mp4",
          file,

          {
            progress: function (percentage, cpt) {
              console.log("打印进度", percentage);
            },
          }
        );
        console.log(result, "12354656");
        this.loading = false;
        this.videoUrl = result.url;
        this.ruleForm.url = this.videoUrl;
      } catch (e) {
        console.log(e);
      }
    },
    // uploadProcess(event, file, fileList) {
    //   console.log(event);
    //   console.log(file);
    //   console.log(fileList);
    // },
  },
};
</script>


<style lang="scss" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #352e2e;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.d {
  position: relative;
}
.d1 {
  position: absolute;
  top: 280px;
  left: 380px;
}
</style>
