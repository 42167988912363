<template>
  <div class="user">
    <div class="block-quote">
      <el-form :inline="true" :model="form" ref="form">
        <el-form-item style="float: right">
          <el-button
            type="primary"
            @click="addData()"
            style="margin-bottom: 10px"
            >添加</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <page-table
      ref="dataTable"
      :data="videoSourceList"
      @changeCurrentPage="changeCurrent"
    >
      <el-table-column label="序号" align="center">
        <template slot-scope="scope">
          <span>{{
            (page.currentPage - 1) * page.pageSize + scope.$index + 1
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="视频封面图地址" align="center">
        <template slot-scope="scope">
          <img
            :src="scope.row.img"
            min-width="70"
            height="70"
            @click="showVideo(scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column prop="title" label="视频标题" align="center">
      </el-table-column>
      <el-table-column prop="content" label="视频描述" align="center">
      </el-table-column>
      <el-table-column prop="status" label="视频状态显示" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.status == 1">显示</span>
          <span v-if="scope.row.status == 2">隐藏</span>
        </template>
      </el-table-column>
      <el-table-column prop="pv" label="视频点击数量" align="center">
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="editData(2, scope.row)"
            >编辑</el-button
          >
          <el-button @click="deleteData(scope.row)" type="text" size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </page-table>
    <!-- 显示视频的dialog -->
    <el-dialog
      title="视频"
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <videoPlayer :videoUrl="videoUrl"> </videoPlayer>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <edit-data ref="editData" />
  </div>
</template>

<script>
import { videolist, videoadd, videoedit, videodelete } from "@/request/api";
import { checkPermission } from "@/utils/permissions";
import pageTable from "@/components/pageTable.vue";
import editData from "./components/editData";
import videoPlayer from "@/components/videoPlayer.vue";
import { VueVideoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";
import { log } from "mathjs";

export default {
  name: "material",
  components: {
    pageTable,
    editData,
    videoPlayer,
    VueVideoPlayer,
  },
  data() {
    return {
      videoSourceList: [], //视频列表
      dialogVisible: false,
      // 定义视频路径
      videoUrl: "",
      form: {
        url: "",
        img: "",
        title: "",
        content: "",
        status: "",
        pv: "",
      },
      page: {
        //分页信息
        currentPage: 1, //当前页
        pageSize: 10, //每页条数
        total: 0, //总条数
      },
      tableSelectList: [], // 多选列表
    };
  },
  watch: {},
  created() {
    this.getVideoList(); //获取用户列表
  },
  mounted() {},
  computed: {},
  methods: {
    checkPermission,
    //获取视频列表
    getVideoList() {
      //   console.log(sessionStorage.getItem("token"));
      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };
      let params = {
        page: this.page.currentPage,
        limit: this.page.pageSize,
        token: sessionStorage.getItem("token"),
      };
      videolist(params, config).then((res) => {
        console.log(res);
        // console.log(res.data.data.data);
        this.videoSourceList = res.data.data.data;
        this.page.total = res.data.data.total;
        console.log(this.videoSourceList, "1111");
        this.$refs.dataTable.setPageInfo({
          total: this.page.total,
        });
      });
    },
    // 切换分页
    changeCurrent(page, size) {
      this.page.currentPage = page;
      this.page.pageSize = size;
      this.getVideoList();
    },
    // 添加视频素材
    addData() {
      this.$refs.editData.show(1, {});
    },
    // 编辑视频素材
    editData(type, row) {
      console.log(row);
      let rowData = row;
      this.$refs.editData.show(2, JSON.parse(JSON.stringify(rowData)));
    },
    deleteData(row) {
      this.$confirm("是否删除此数据？", "提示", {
        type: "warning",
      })
        .then(async () => {
          let params = {
            token: sessionStorage.getItem("token"),
            id: row.id,
          };
          videodelete(params).then((res) => {
            if (res.data.status == "200") {
              this.getVideoList();
              this.$message.success("删除成功");
            } else {
              this.$message.error(res.data.msg);
              this.getVideoList();
            }
          });
        })
        .catch(() => {});
    },
    //点击显示视频
    showVideo(row) {
      console.log(row);
      console.log(this.videoUrl);
      this.videoUrl = row.url;
      this.dialogVisible = true;
      console.log(this.videoUrl);
      // this.playerOptions.sources[0].src = row.url;
    },
    handleClose() {
      this.videoUrl = "";
      console.log(this.videoUrl);
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
